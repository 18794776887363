import { template as template_fa6bdd06b1644889b67a51b6be460c8f } from "@ember/template-compiler";
const SidebarSectionMessage = template_fa6bdd06b1644889b67a51b6be460c8f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
