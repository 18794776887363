import { template as template_ff682fa66079431f998afa64e00a4b18 } from "@ember/template-compiler";
const FKText = template_ff682fa66079431f998afa64e00a4b18(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
