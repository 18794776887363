import { template as template_7b4a6ce523914d4490301976c1210fea } from "@ember/template-compiler";
const FKControlMenuContainer = template_7b4a6ce523914d4490301976c1210fea(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
